import { Box, Button, OSKIcon, OSKThemeType, Table, TableColumn, Typography } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { Sensor, noop } from 'oskcore';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { date_format_long, date_format_longer } from '~/utils';

type ToolsNotationTableProps = {
    /** The tasking data to display in the table */
    data?: any;
    /** A list of sensor metadata from redux */
    sensors?: Sensor[];
    /** A method that's called when a row is expanded */
    onRowExpand?: (row: any, idx: number, expanded: boolean) => void;
};

const ToolsNotationTable = ({ data, sensors, onRowExpand }: ToolsNotationTableProps) => {
    const theme = useTheme() as OSKThemeType;

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            {
                Header: 'Incident Name',
                accessor: 'title',
                disableSortBy: true,
            },
            {
                Header: 'Created Date',
                accessor: 'createdDate',
                Cell: ({ value }: any) => {
                    return date_format_long(value);
                },
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                Cell: ({ value }: any) => {
                    return date_format_long(value);
                },
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                Cell: ({ value }: any) => {
                    return value ? date_format_long(value) : 'Ongoing';
                },
            },
            {
                Header: 'Sensors',
                accessor: 'sensors',
                Cell: ({ value }: any) => {
                    return (value as number[]).map(sensorId => sensors?.find(s => s.osk_id === sensorId)?.osk_sensor_name).join(', ');
                },
            },
            {
                Header: '',
                accessor: 'edit',
                Cell: ({ row }: any) => {
                    return <Button variant="action">Edit</Button>;
                },
            },
        ],
        [],
    );

    if (!data) {
        return (
            <Box grow center="horizontal">
                <Box col center="horizontal" w={500} h={240} style={{ textAlign: 'center' }}>
                    <OSKIcon code="no-captures" width={300} style={{ marginBottom: '-35px' }} />
                    <Typography variant="heading3">No notations</Typography>
                    <Typography variant="body3" m={12}>
                        There are currently no active notations in effect
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Table
            columns={columns}
            data={data}
            singleRowColor
            showLines
            defaultExpandRows={data.length === 1 ? [0] : []}
            variant="neutral"
            style={{ border: `2px solid ${theme.colors.lightGray}`, color: 'black' }}
            onRowExpand={onRowExpand}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    const { sensors } = state.osk;

    return {
        sensors,
    };
};

export default connect(mapStateToProps, noop)(ToolsNotationTable);

export type { ToolsNotationTableProps as ToolsTaskSearchTableProps };
