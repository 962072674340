import React from 'react';
import { Button, OSKIcon, OSKThemeType } from 'oskcomponents';
import { RootState } from '~/redux/store';
import { connect, useDispatch } from 'react-redux';
import { noop } from 'oskcore';
import { setTimelineScrubberVisible } from '~/redux/modules/data/search';
import { useTheme } from 'styled-components';

const NUM_BUCKETS_SHOW_TIMELINE = 5;

type ToggleTimelineSliderButtonProps = {
    /** From redux, whether or not the timeline scrubber is visible */
    timelineScrubberVisible?: boolean;
    /** From redux, the number of buckets the search results are sorted into. */
    numTimelineScrubberBuckets: number;
};

const ToggleTimelineSliderButton = ({
    timelineScrubberVisible,
    numTimelineScrubberBuckets,
}: ToggleTimelineSliderButtonProps) => {
    const dispatch = useDispatch();
    const theme = useTheme() as OSKThemeType;
    return (
        <Button
            variant={timelineScrubberVisible ? 'action' : 'clear'}
            title="Show Timeline Scrubber"
            style={{
                width: '40px',
                padding: '0',
                display: numTimelineScrubberBuckets < NUM_BUCKETS_SHOW_TIMELINE ? 'none' : 'initial',
            }}
            onClick={() => {
                dispatch(setTimelineScrubberVisible(!timelineScrubberVisible));
            }}
            border={timelineScrubberVisible ? '' : `1px solid ${theme.colors.black400}`}
        >
            <OSKIcon
                code="timeline"
                style={{ justifyContent: 'center' }}
                fill={timelineScrubberVisible ? 'white' : theme.colors.black400}
            />
        </Button>
    );
};

const mapStateToProps = (state: RootState) => {
    const { timelineScrubberVisible, numTimelineScrubberBuckets } = state.data.search;

    return {
        timelineScrubberVisible,
        numTimelineScrubberBuckets,
    };
};

export type { ToggleTimelineSliderButtonProps };
export default connect(mapStateToProps, noop)(ToggleTimelineSliderButton);
