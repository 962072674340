import { ListEntry, Select, SelectProps } from 'oskcomponents';
import { PublicDataProduct, noop } from 'oskcore';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';

export type DummyEula =  {
    display_name: string;
    id: string;
}

export type EulaTypePickerProps = {
    /** The list of supported data products */
    eulas: Array<DummyEula>;
    /** The method to invoke when the selection changes */
    onChange: (option: string) => void;
    /** The default entry to pick */
    defaultValue?: string;
} & Omit<Omit<Omit<Omit<Omit<SelectProps, 'name'>, 'items'>, 'variant'>, 'inverted'>, 'onChange'>;

export const EulaPicker = ({
    onChange,
    eulas,
    defaultValue,
    ...props
}: EulaTypePickerProps) => {
    const eulaTypeOptions: Array<ListEntry> = eulas.map((eula) => ({
        label: eula.display_name,
        value: eula.id,
    }));

    // Derive the default option
    let defaultEulaType = defaultValue ?? eulaTypeOptions[0];

    // Validate the default, just in case something
    // old has been stuck in localStorage.
    let valid = false;
    for (const option of eulaTypeOptions) {
        if (option.value === defaultEulaType) {
            valid = true;
        }
    }

    // If the option is not valid, default to the first valid thing.
    if (!valid) {
        defaultEulaType = eulaTypeOptions[0];
    }

    const [selectedEulaType, setSelectedEulaType] = useState<string>(defaultEulaType);

    useEffect(() => {
        onChange && onChange(selectedEulaType);
    }, [selectedEulaType, onChange]);

    return (
        <Select
            name="eula_type"
            inverted
            variant="primary"
            items={eulaTypeOptions}
            defaultValue={selectedEulaType}
            onSelect={(option) => {
                if (option) {
                    setSelectedEulaType(option.value);
                }
            }}
            {...props}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    const eulas: Array<DummyEula> = [
        {display_name: 'Creative Commons', id: 'cc' },
        {display_name: 'MIT', id: 'mit' },
        {display_name: 'Honor Code', id: 'plz' },
    ]
    return {
        eulas
    };
};

export default connect(mapStateToProps, noop)(EulaPicker);
