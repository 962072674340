import React from 'react';
import { Box, Text } from 'oskcomponents';
import styled from 'styled-components';
import { date_format_short } from '~/utils';
import CSS from 'csstype';

type FadeInDateProps = {
    /** Passthrough for styled-component */
    className?: string;

    /** The date to display */
    date: Date;

    /** Whether or not this should fade out, or persist */
    fade?: boolean;

    /** Style passthrough */
    style?: CSS.Properties;
};
const FadeInDate = styled(({ className, date, fade = true, style, ...props }: FadeInDateProps) => {
    return (
        <Text className={className} {...props}>
            <Box style={style} className="fade-in-date-container">
                {date_format_short(date)}
            </Box>
        </Text>
    );
})`
    & {
        position: absolute;
        color: ${(props: FadeInDateProps) => (props.fade ? '#626262' : '#000')};
        height: ${(props: FadeInDateProps) => (props.fade ? '80px' : '14px')};
        bottom: 0px;
        width: 50px;
        text-align: center;
        opacity: ${(props: FadeInDateProps) => (props.fade ? 0 : 1)};
        transition: opacity ease-in-out 0.2s;
        text-stroke: 2px red;
    }
    .fade-in-date-container {
        height: calc(100% - 6px);
        align-items: flex-end;
        justify-content: center;

        font-size: 0.6rem;
        font-weight: 400;
    }
    &:hover {
        opacity: 1;
    }
`;

export { FadeInDate };
export type { FadeInDateProps };
