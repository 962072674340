import React, { useCallback, useEffect } from 'react';
import { fround, NavComponent } from 'oskcore';
import { useNavigate, useParams } from 'react-router-dom';
import { LeafletEvent, Map } from 'leaflet';
import { Box } from 'oskcomponents';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import { useSessionStorage } from '~/hooks';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import EditableMap from '~/organisms/map/EditableMap';
import AoiTools from '~/organisms/map/AoiTools';
import TaskingAoiTools from '~/organisms/map/TaskingAoiTools';
import { debounce } from 'lodash';
import SearchSidePanel from '~/organisms/SearchSidePanel';
import CartWizardPopup from '~/organisms/CartWizardPopup';
import LeafletFootprints from '~/organisms/LeafletFootprints';
import OverlayKey from '~/organisms/map/OverlayKey';
import ActiveAoi from '~/organisms/map/ActiveAoi';
import TaskingSidePanel from '~/organisms/TaskingSidePanel';
import { useDispatch } from 'react-redux';
import { emptyCart, setWizardFlow } from '~/redux/modules/data/cart';
import PromptWhenLeavingHomeView from '~/atoms/PromptWhenLeavingHomeView';
import PromptWhenLeavingTaskingView from '~/atoms/PromptWhenLeavingTaskingView';
import SearchTopBar from '~/organisms/SearchTopBar';
import MapSearchButton from '~/organisms/map/MapSearchButton';
import CartButton from '~/organisms/CartButton';
import { setLastAppAccessed } from '~/utils';
import { doSearchAsyncForTaskId, setSearchFilterDates } from '~/redux/modules/data/search';
import FootprintImageOverlays from '~/organisms/map/FootprintImageOverlays';
import { OSKToastView } from '~/molecules/OSKToastView';
import HiddenFootprintWarning from '~/organisms/map/HiddenFootprintWarning';
import ConnectedTimelineSlider from '~/organisms/map/ConnectedTimelineSlider';
import EnviHeaderModal from '~/organisms/map/EnviHeaderModal';
import OSKHomeViewMapZoomer from '~/organisms/map/OSKHomeViewMapZoomer';

export type HomeViewMode = 'tasking' | 'search';

export type HomeViewProps = {
    /** The mode we are rendering for. Side panel will be different for tasking vs. search */
    mode: HomeViewMode;
};

const HomeView = ({ mode }: HomeViewProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { position } = useParams() as any;
    const searchParams = new URLSearchParams(window.location.search);
    const [, setSessionStorage] = useSessionStorage();

    setLastAppAccessed('data');

    useEffect(() => {
        const taskId = searchParams.get('taskId') || searchParams.get('taskid');

        /* If we were passed some capture IDs, add them all to the cart
        and show the wizard. */
        if (taskId) {
            // Clear the cart
            dispatch(emptyCart());

            // Add the captures to the cart
            dispatch<any>(doSearchAsyncForTaskId(taskId, true));

            // Show the wizard
            dispatch(setWizardFlow('search'));
        }
    }, []);

    const saveMapCoordinates = useCallback(
        (evt: LeafletEvent) => {
            const map = evt.target as Map;
            const { lat, lng } = map.getCenter();
            const froundLat = fround(lat, 3);
            const froundLng = fround(lng, 3);
            const zoom = map.getZoom();

            setSessionStorage('map', { coords: [froundLat, froundLng], zoom: zoom });
            switch (mode) {
                case 'search': {
                    window.history.replaceState(
                        null,
                        '',
                        `/map/@${froundLat},${froundLng},${zoom}/${window.location.search}`,
                    );
                    break;
                }
                case 'tasking': {
                    window.history.replaceState(
                        null,
                        '',
                        `/tasking/@${froundLat},${froundLng},${zoom}/${window.location.search}`,
                    );
                    break;
                }
            }
        },
        [navigate],
    );

    return (
        <OSKToastView nogradient>
            <AppShimmerBar />
            {mode === 'search' && <PromptWhenLeavingHomeView />}
            {mode === 'tasking' && <PromptWhenLeavingTaskingView />}
            <Box style={{ flexGrow: 1 }}>
                <ActiveModeSwitcher
                    onProceed={() => {
                        dispatch(emptyCart());
                    }}
                />
                <NavComponent>
                    <SigmaNavItems>
                        <Box style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            {mode === 'search' && <CartButton />}
                        </Box>
                    </SigmaNavItems>
                </NavComponent>
                <EditableMap position={position} onMove={debounce(saveMapCoordinates, 300)} zoomPosition="bottomright">
                    {(() => {
                        switch (mode) {
                            case 'search':
                                return (
                                    <Box
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            position: 'relative',
                                            pointerEvents: 'none',
                                        }}
                                        col
                                    >
                                        <ConnectedTimelineSlider
                                            onRangeUpdated={(start, end) => {
                                                dispatch(setSearchFilterDates(start, end));
                                            }}
                                        />
                                        <EnviHeaderModal />
                                        <HiddenFootprintWarning />
                                        <MapSearchButton />
                                        <OverlayKey />
                                        <Box col grow>
                                            <SearchTopBar />
                                            <SearchSidePanel expandable collapsible visible={false} />
                                        </Box>
                                        <LeafletFootprints />
                                        <FootprintImageOverlays />
                                        <AoiTools top={75} />
                                        <OSKHomeViewMapZoomer />
                                    </Box>
                                );
                            case 'tasking':
                                return (
                                    <Box
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                        col
                                    >
                                        <ActiveAoi />
                                        <TaskingSidePanel />
                                        <TaskingAoiTools />
                                    </Box>
                                );
                        }
                    })()}

                    <CartWizardPopup />
                </EditableMap>
            </Box>
        </OSKToastView>
    );
};

export default HomeView;
